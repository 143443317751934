import useFluxState from "@/components/hooks/useFluxState";
import React, { Children } from "react";
import { useEffect } from "react";
import { useState } from "react";

export interface TextAreaProps
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  className?: string;
  label: string;
  name: string;
  value?: string;
  error?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  className,
  name,
  rows,
  maxLength,
  value,
  error,
  onBlur,
  onChange,
  ...props
}) => {
  const [inputValue, setInputValue] = useFluxState(value || "",[value]);
  const [charsLength, setCharslength] = useState(inputValue.length);
  const [showCounter, setShowCounter] = useState(false);

  useEffect(() => {
    if (maxLength && inputValue.length > maxLength) {
      const cutString = inputValue.substring(0, maxLength);
      setInputValue(cutString);
    }

    setCharslength(inputValue.length);
    // onChange()
  }, [maxLength, inputValue]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = e.target as HTMLTextAreaElement;
    let cutString: string;

    if (maxLength && input.value.length > maxLength) {
      cutString = input.value.substring(0, maxLength);
    } else {
      cutString = input.value;
    }

    e.target["value"] = cutString;

    setInputValue(cutString);
    setCharslength(cutString.length);
    onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setShowCounter(false);
    onBlur(e);
  };

  return (
    <div className={`w-full relative font-action mt-4 first:mt-0 ${className}`}>
      <textarea
        {...props}
        name={name}
        id={name}
        rows={rows || 5}
        placeholder=" "
        value={inputValue}
        onChange={handleChange}
        onFocus={() => setShowCounter(true)}
        onBlur={handleBlur}
        className={`input-field w-full !outline-none border border-grey-divider bg-grey-fields-100 bg-opacity-40 rounded-lg text-base placeholder-shown:text-1sm text-black-secondary px-4 sm:px-5 py-4 `}
      />
      <label
        htmlFor={name}
        className="text-sm text-placeholder absolute cursor-text top-3 left-4 sm:left-5 !ml-0 pointer-events-none"
      >
        {label}
      </label>
      {(error || showCounter) && (
        <div className="flex items-center justify-between">
          {error && (
            <div className="text-accent-red-500 text-xs font-medium flex items-center font-body">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="mr-1">
                <path
                  d="M7.018 3.55288L2.15392 11.6731C2.05363 11.8467 2.00057 12.0437 2 12.2442C1.99944 12.4447 2.0514 12.6419 2.15071 12.8162C2.25003 12.9904 2.39323 13.1356 2.56607 13.2373C2.73892 13.339 2.93538 13.3937 3.13592 13.3959H12.8641C13.0646 13.3937 13.2611 13.339 13.4339 13.2373C13.6068 13.1356 13.75 12.9904 13.8493 12.8162C13.9486 12.6419 14.0006 12.4447 14 12.2442C13.9994 12.0437 13.9464 11.8467 13.8461 11.6731L8.982 3.55288C8.87963 3.3841 8.73548 3.24456 8.56347 3.14772C8.39146 3.05088 8.1974 3 8 3C7.8026 3 7.60854 3.05088 7.43653 3.14772C7.26452 3.24456 7.12037 3.3841 7.018 3.55288V3.55288Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M8 6.50452V8.8016" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 11.0992H8.00718" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="inline-block">{error}</span>
            </div>
          )}
          {maxLength && (
            <div
              className={`ml-auto transition-all transform easeLinear duration-100 ${
                showCounter ? "max-h-20 translate-y-0" : "max-h-0 -translate-y-1 overflow-hidden"
              }`}
            >
              <span className="text-placeholder text-xs font-medium inline-block pt-0.5">
                {charsLength}/{maxLength} characters
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TextArea;
