import { useEffect, useRef } from "react";

const usePreventFirstRun = (func: () => any, deps: any[]) => {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    func();
  }, deps);
};

export default usePreventFirstRun;
