import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function useFluxEffect<T>(currentState: T, callback: (param: T) => void): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>();

  useEffect(() => {
    setState(currentState);
  }, [currentState]);

  return [
    state,
    (s: T) => {
      setState(s);
      callback(s);
    },
  ];
}

function useFluxState<T>(currentState: T, deps?: React.DependencyList): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(currentState);

  useEffect(() => {
    setState(currentState);
  }, deps ?? [currentState]);

  return [state, setState];
}

export default useFluxState;
